// extracted by mini-css-extract-plugin
export var container = "post-module--container--48521";
export var content = "post-module--content--90f88";
export var date = "post-module--date--4c3fc";
export var link = "post-module--link--2f930";
export var mainTitle = "post-module--mainTitle--f7fbf";
export var nav = "post-module--nav--94bca";
export var next = "post-module--next--36a1b";
export var paragraphContent = "post-module--paragraphContent--f6c07";
export var prev = "post-module--prev--834a2";
export var prose = "post-module--prose--2ff29";